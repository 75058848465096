// Google Places API
let autocomplete;

/**
 * Callback function used by the google maps API to config the maps
 */
function initMap() {
  if ($("#map").length) {
    var directionsService = new google.maps.DirectionsService();
    var directionsRenderer = new google.maps.DirectionsRenderer();

    // Getting the coordinates of the suburb and the location
    const originLat = parseFloat(document.getElementById("map").dataset.latlocation);
    const originLng = parseFloat(document.getElementById("map").dataset.lnglocation);
    const destLat =   parseFloat(document.getElementById("map").dataset.latsuburb);
    const destLng =   parseFloat(document.getElementById("map").dataset.lngsuburb);

    var start = new google.maps.LatLng( originLat, originLng );
    var end = new google.maps.LatLng( destLat, destLng );

    // Creating the Map with specified options
    var mapOptions = {
      zoom: 14,
      center: end
    };
    var map = new google.maps.Map(document.getElementById("map"), mapOptions);
    directionsRenderer.setMap(map);

    // Creating the route from the Suburb to the Store
    var request = {
      origin: start,
      destination: end,
      travelMode: "DRIVING",
    };
    directionsService.route(request, function(response, status) {
      if (status == "OK") {
        directionsRenderer.setDirections(response);
        const suburbName = document.getElementById("location-description-route__part1").dataset.suburbname.toString();

        // Assigning the distance of the route from the suburb to the store in the description of the single suburb template
        if(suburbName && !suburbName.includes("is only")) { // check if the text hasn't been put in already
          document.getElementById("location-description-default").innerHTML = "";
          const distanceText =  suburbName+" is only <span class='distance'>"+response.routes[0].legs[0].distance.text+"</span> from our";

          const part1 = document.getElementById("location-description-route__part1");
          const part2 = document.getElementById("location-description-route__part2");
          // add the information to the spans
          part1.innerHTML = distanceText;
          //part2.innerHTML = "wich";
          // make the spans appear
          part1.style.display = "initial";
          part2.style.display = "initial";

        }
      } else {
        console.log('Error creating the route!', response);
      }
    });
  }
  if ($("#location-single-map").length) {
    //INIT Location Map
    var mapStyles = [{
      featureType: "poi",
      elementType: "labels",
      stylers: [{
        visibility: "off",
      }, ],
    }, ];

    var map = new google.maps.Map(
      document.getElementById("location-single-map"), {
        zoom: 15,
        scrollwheel: false,
        center: {
          lat: jQuery("#location-single-map").data("lat"),
          lng: jQuery("#location-single-map").data("lng"),
        },
        styles: mapStyles
      }
    );
    var image = {
      url: "/wp-content/themes/rent-a-space/assets/img/icons/icon-marker-orange.svg",
      size: new google.maps.Size(56, 64),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(28, 32),
    };
    var marker = new google.maps.Marker({
      position: {
        lat: jQuery("#location-single-map").data("lat"),
        lng: jQuery("#location-single-map").data("lng"),
      },
      map: map,
      icon: image,
    });
    //
  }
}
$(document).ready(function() {

  initMap();
  //initMap2();
});
